import React from 'react'
import Layout from '../components/Layout'
import styled from "styled-components";

const NotFoundPage = () => (
  <Layout>
    <StyledDiv>
      <h1>NOT FOUND</h1>
      <p>指定したページは存在しません。</p>
    </StyledDiv>
  </Layout>
)

export default NotFoundPage

const StyledDiv = styled.div`
  padding: 5vh 5vw 5vh 5vw;
`;